<template>
  <b-container class="pl-0 pl-sm-15 pr-0 pr-sm-15 h-100 pt-10 pb-10">
    <b-row class="h-100">
      <b-col xl="5" lg="6" md="8" sm="10" xs="10" cols="12" class="m-auto">
        <div class="col col-auto mt-5 mt-s-10">
          <b-card v-if="!hasSubmitted" border-variant="secondary" class="mb-20">
            <b-alert :show="true" v-if="error" variant="danger">{{
              error.message
            }}</b-alert>
            <div v-if="quickstart" class="col mt-3 d-flex">
              <span
                class="spinner-border spinner-border-lg center-spinner me-3"
                v-if="loading"
              ></span>
              {{ $t('forgotten.wait') }}
            </div>
            <form class="p-4" v-if="!quickstart">
              <PulsFormInput
                v-model="organization"
                :disabled="loading"
                autocomplete="organization"
                type="organization"
                name="organization"
                :label="$t('forgotten.input.organization')"
              />

              <div class="my-5">
                <PulsFormInput
                  v-model="username"
                  :disabled="loading"
                  autocomplete="username"
                  type="username"
                  name="username"
                  :label="$t('forgotten.input.username')"
                />
              </div>
              <b-alert show variant="info">
                <small>{{ $t('forgotten.helptext') }}</small>
              </b-alert>

              <div class="row mt-7">
                <div class="col pl-0 d-flex justify-content-center">
                  <button
                    v-on:click="$router.push({ name: 'Login' })"
                    v-if="!loading"
                    class="btn btn-secondary me-3"
                  >
                    {{ $t('forgotten.backToLogin') }}
                  </button>
                  <button
                    v-on:click="requestCode($event)"
                    v-if="!loading"
                    class="btn btn-primary"
                    type="submit"
                    :disabled="!emailValidates"
                  >
                    {{ $t('forgotten.requestcode.submit') }}
                  </button>
                  <span
                    class="spinner-border spinner-border-lg center-spinner"
                    v-if="loading"
                  ></span>
                </div>
              </div>
            </form>
          </b-card>
          <b-card v-else border-variant="secondary" style="max-width: 35rem">
            <b-alert :show="true" v-if="error" variant="danger">{{
              error.message
            }}</b-alert>
            <div v-if="quickstart">
              {{ $t('forgotten.helptext-quickstart', { email: username }) }}
            </div>

            <div v-if="!shouldHideCodeField">
              <PulsFormInput
                v-model="code"
                :disabled="loading"
                autocomplete="none"
                type="number"
                name="code"
                :label="$t('forgotten.input.code')"
              />
            </div>
            <div class="mt-5">
              <PulsFormInput
                v-model="password"
                :disabled="loading"
                autocomplete="new-password"
                type="password"
                name="password"
                :label="$t('forgotten.input.password')"
              />
            </div>
            <div class="mt-3">
              <small class="text-muted">{{ $t('forgotten.helptext2') }}</small>
            </div>
            <div class="row mt-5">
              <div class="col d-flex justify-content-center">
                <b-button
                  :disabled="!validCodeForm"
                  v-on:click="setNewPassword($event)"
                  v-if="!loading"
                  variant="primary"
                  >{{ $t('forgotten.setNewPassword.submit') }}</b-button
                >
                <span
                  class="spinner-border spinner-border-lg center-spinner"
                  v-if="loading"
                ></span>
              </div>
            </div>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { PulsFormInput } from '@puls-solutions/puls-ui-components'
import Amplify from 'aws-amplify'

const Isemail = {
  validate: (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const valid = re.test(String(email).toLowerCase())
    return valid
  }
}

export default {
  name: 'ForgotPassword',
  components: { PulsFormInput },
  data() {
    return {
      organization: '',
      username: '',
      password: '',
      code: '',
      shouldHideCodeField: false,
      hasSubmitted: false,
      loading: false,
      error: null,
      quickstart: false // if we should just automatically proceeed, without showing texts
    }
  },
  mounted() {
    if (localStorage.organizationName) {
      this.organization = localStorage.organizationName
    }
    if (
      this.$route.params.organization &&
      this.$route.params.username &&
      this.$route.params.code
    ) {
      this.organization = this.$route.params.organization
      this.username = this.$route.params.username
      this.code = this.$route.params.code
      this.hasSubmitted = true
      this.shouldHideCodeField = true
    }
    if (this.$route.query.organization) {
      this.organization = this.$route.query.organization
    }
    if (this.$route.query.username) {
      const email = decodeURIComponent(this.$route.query.username)
      if (Isemail.validate(email)) {
        this.username = email
      }
    }
    if (this.$route.query.quickstart && this.organization && this.username) {
      this.quickstart = true
      this.requestCode()
    }
  },
  computed: {
    emailValidates() {
      return Isemail.validate(this.username)
    },
    validCodeForm() {
      return this.code !== '' && !isNaN(this.code) && this.password.length > 2
    }
  },
  methods: {
    requestCode: function (event) {
      this.error = null
      this.loading = true
      if (event) event.preventDefault()

      if (this.error) return

      this.loading = true
      localStorage.organizationName = this.organization
      Amplify.API.get('PublicAPI', '/organizations/' + this.organization, {})
        .then((orgResult) => {
          localStorage.orgData = JSON.stringify(orgResult)
          Amplify.configure({
            Auth: {
              identityPoolId: orgResult.identityPoolId,
              region: 'eu-west-1',
              userPoolId: orgResult.userPoolId,
              userPoolWebClientId: orgResult.userPoolWebClientId,
              clientMetadata: {
                organizationName: this.organization,
                organizationFullName: orgResult.organizationFullName,
                locale: 'en' // TODO: Use some kind of default
              }
            }
          })
        })
        .then(() => {
          Amplify.Auth.forgotPassword(this.username)
            .then(() => {
              this.hasSubmitted = true
              this.loading = false
            })
            .catch((err) => {
              this.hasSubmitted = false
              this.quickstart = false
              this.loading = false
              if (err.code === 'UserNotFoundException') {
                this.error = {
                  message:
                    this.username +
                    ' - ' +
                    this.$t('error.UserNotFoundException')
                }
                this.username = ''
              } else {
                this.error = err
              }
            })
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.error = {
              message: this.$t('error.OrganizationNotFoundException')
            }
          } else {
            this.error = error
          }
          this.loading = false
          this.quickstart = false
          this.hasSubmitted = false
        })
    },
    setNewPassword: function (event) {
      this.error = null
      this.loading = true
      if (event) event.preventDefault()
      Amplify.API.get('PublicAPI', '/organizations/' + this.organization, {})
        .then((orgResult) => {
          localStorage.orgData = JSON.stringify(orgResult)
          Amplify.configure({
            Auth: {
              identityPoolId: orgResult.identityPoolId,
              region: 'eu-west-1',
              userPoolId: orgResult.userPoolId,
              userPoolWebClientId: orgResult.userPoolWebClientId,
              clientMetadata: {
                organizationName: this.organization,
                organizationFullName: orgResult.organizationFullName,
                locale: 'en' // TODO: Use some kind of default
              }
            }
          })
        })
        .then(() => {
          Amplify.Auth.forgotPasswordSubmit(
            this.username,
            this.code,
            this.password
          )
            .then(() => {
              this.loading = false
              this.$router.push('/login')
            })
            .catch((err) => {
              this.loading = false
              this.password = ''
              this.error = err
            })
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.error = {
              message: this.$t('error.OrganizationNotFoundException')
            }
          } else {
            this.error = error
          }
          this.loading = false
          this.hasSubmitted = false
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
